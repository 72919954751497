import React, { useCallback, useEffect, useState } from 'react'
import cl from './index.module.scss'
import { apiAuth, checkAuthorized, setAuthorized } from '../../../core/auth'
import { LoaderFunctionArgs, redirect, useNavigate } from 'react-router-dom'
import { urls } from '../../../routes/urls'



type StageType = 'user_wait' | 'auth' | 'authorization'
export const LoginPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const [stage, setStage] = useState('user_wait' as StageType)
    const [err, setErr] = useState('')
    useEffect(() => {
        if (stage != 'auth') {
            return
        }
        setStage('authorization')
        setTimeout(() => {
            setStage('user_wait')
        }, 3000)
    }, [stage])
    const auth = useCallback((login: string, pass: string) => {
        setStage('auth')
        apiAuth(login, pass).then(res => {
            setAuthorized(res)
            navigate(urls.adminDashboard)
        }).catch(err => {
            console.error(err)
            setErr(JSON.stringify(err, null, '  '))
        }).finally(() => {
            setStage('user_wait')
        })
    }, [])
    const disabled = stage != 'user_wait'
    return (
        <div>
            <h1>Login page</h1>
            <LoginForm auth={auth} disabled={disabled}/>
            <div>
                {err}
            </div>
        </div>
    )
}

const LoginForm: React.FC<{disabled: boolean, auth:(login: string, pass: string) => void}>
= ({ auth, disabled }) => {
    const [login, setLogin] = useState('admin')
    const [password, setPassword] = useState('123')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        auth(login, password)
    }
    return (
        <form className={cl.form}
            onSubmit={handleSubmit}>
            <label htmlFor="login">Логин:</label>
            <input type="text" id="login" value={login}
                onChange={(event) => setLogin(event.target.value)}
                disabled={disabled}
            />

            <label htmlFor="password">Пароль:</label>
            <input type="password" id="password" value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={disabled}
            />
            <span></span>
            <div className={cl.form__btn}>
                <button type="submit" disabled={disabled}>Вход</button>
            </div>

        </form>
    )
}


export function loader(r: LoaderFunctionArgs) {
    const [isAuthorized, message] = checkAuthorized()
    if (!isAuthorized) {
        return {}
    }
    return redirect(urls.adminDashboard)
}
