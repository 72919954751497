import { ApiRequest } from './api'
import { Need, NeedType, PeriodType, Temple, TempleProject } from './types'


export type Dictionary = {
    temples: Temple[]
    projects: TempleProject[]
    need_types: NeedType[]
    period_types: PeriodType[]
}

export type Dashboard = {
    needs: Need[]
} & Dictionary

export async function apiDashboard() {
    return ApiRequest<Dashboard>({
        method: 'post',
        url: '/api/admin/dashboard',
    })
}


export async function apiDictionary() {
    return ApiRequest<Dashboard>({
        method: 'post',
        url: '/api/admin/dictionary',
    })
}