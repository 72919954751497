import React, { useCallback, useEffect, useState } from 'react'
// import cl from './index.module.scss';


type Props = {
    title: string
    value: boolean
    change: (v: boolean) => void
    disabled?: boolean
}

export const Checkbox: React.FunctionComponent<Props> = (props) => {
    const [formId, setFormId] = useState(`${Math.random()}`)
    useEffect(() => {
        setFormId(`${Math.random()}`)
    }, [])
    return (
        <>
            <CheckboxItem formId={formId}
                {...props}
            />
            <label htmlFor={formId}>{props.title}</label>
        </>
    )
}


type InputProps = {
    formId?: string
    value: boolean
    change: (v: boolean) => void
    disabled?: boolean
}

export const CheckboxItem: React.FunctionComponent<InputProps> = (props) => {
    const change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        props.change(!props.value)
    }, [props.value, props.change])
    return (
        <input
            id={props.formId}
            type="checkbox"
            checked={props.value}
            onChange={change}
            disabled={props.disabled === true}
        />
    )
}


