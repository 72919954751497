import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cl from './index.module.scss'
import { Need } from '../../../core/types'
import { Dictionary } from '../../../core/dashboard'
import { DateTime } from '../Forms/DateTime'
import { Checkbox } from '../Forms/Checkbox'
import { curTime, dtToTimeDateString } from '../../../core/helperTime'
import { Number } from '../Forms/Number'
import { DuetButtons } from '../../ActionButtons/DuetButtons'
import { apiSaveNeed } from '../../../core/apiNeed'
import { ImageFile } from '../Forms/ImageFile'


type Props = {
    need: Need
    setNeed: React.Dispatch<React.SetStateAction<Need | undefined>>
    dict: Dictionary
}

type StageType = 'user_wait' | 'save' | 'saving'
export const NeedEdit: React.FunctionComponent<Props> = ({ need, setNeed, ...props }) => {
    const [stage, setStage] = useState('user_wait' as StageType)
    useEffect(() => {
        if (stage != 'save') {
            return
        }
        setStage('saving')
        apiSaveNeed(need).then(res => {
            console.info(res)
            setNeed(undefined)
        }).catch((err: any) => {
            alert(JSON.stringify(err, null, '  '))
        }).finally(() => {
            setStage('user_wait')
        })


    }, [stage])
    const makeChange = <T, >(key: keyof Need) => {
        return (val: T) => {
            setNeed((one) => {
                if (!one || one[key] === val) return one
                one[key] = val as never
                return { ...one }
            })
        }
    }
    const makeChangeHtml = <T, >(key: keyof Need) => {
        return (v: React.ChangeEvent<HTMLTextAreaElement>) => {
            const val = v.target.value
            setNeed((one) => {
                if (!one || one[key] === val) return one
                one[key] = val as never
                return { ...one }
            })
        }
    }
    const changeSelectNumber = (key: keyof Need) => {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            const id = parseInt(e.target.value)
            setNeed((one) => {
                if (!one) return one
                one[key] = id as never
                return { ...one }
            })
        }
    }
    const onNeedClose = useCallback((v: boolean) => {
        if (v) {
            setNeed((one) => {
                if (!one) return one
                one['unix_closed'] = curTime(true)
                return { ...one }
            })
        } else {
            setNeed((one) => {
                if (!one) return one
                one['unix_closed'] = 0
                return { ...one }
            })
        }

    }, [setNeed])
    const changeEventInterval = useCallback(() => {
        const fx = makeChange<number | undefined>('event_interval')
        return (v?: number) => v ? fx(v * 60) : fx(v)
    }, [makeChange])
    const changeTempleId = useMemo(() => {
        const fx = makeChange<number | undefined>('temple_id')
        const fx2 = makeChange<number | undefined>('project_id')
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            const option = parseInt(e.target.value)
            fx(option)
            fx2(0)
        }
    }, [makeChange])
    const onBase64 = (base64?: string) => {
        setNeed((one) => {
            if (!one) return
            one['image'] = base64
            return { ...one }
        })
    }

    const cancel = () => {
        setNeed(undefined)
    }
    const save = () => {
        setStage('save')

    }
    const disabled = false
    return (
        <div className={cl.need_edit}>
            New Component NeedEdit
            <form>
                <span></span>
                <div>
                    <Checkbox value={need.is_displayed} change={makeChange<boolean>('is_displayed')}
                        title='Отображать'
                        disabled={disabled}
                    />
                </div>

                <span></span>
                <div>
                    <Checkbox value={!!need.unix_closed && need.unix_closed > 0} change={onNeedClose}
                        title='Сбор закрыт'
                        disabled={disabled}
                    />
                    <span className={cl.info}>{need.unix_closed ? dtToTimeDateString(need.unix_closed) : ''}</span>
                </div>

                <label htmlFor="temple">Храм:</label>
                <select id="temple" value={need.temple_id} onChange={changeTempleId}
                    disabled={disabled}
                >
                    <option key="0" value={-1}>Новый</option>
                    {
                        props.dict.temples.map(one => {
                            return (
                                <option key={one.id} value={one.id}>{one.title}</option>
                            )
                        })
                    }
                </select>

                <label htmlFor="temple">Проект храма:</label>
                <select id="temple" value={need.project_id} onChange={changeSelectNumber('project_id')}
                    disabled={disabled}
                >
                    <option key="-1" value={-1}>Новый</option>
                    <option key="0" value={0}>Без проекта</option>
                    {
                        props.dict.projects.filter(one => one.temple_id == need.temple_id).map(one => {
                            return (
                                <option key={one.id} value={one.id}>{one.title}</option>
                            )
                        })
                    }
                </select>

                <label htmlFor="unix_deadline">До срока:</label>
                <div>
                    <DateTime unix={need.unix_deadline}
                        change={makeChange<number | undefined>('unix_deadline')}
                        disabled={disabled}/>
                    <span className={cl.info}>{need.unix_deadline ? dtToTimeDateString(need.unix_deadline) : ''}</span>

                </div>

                <span></span>
                <div>
                    <Checkbox
                        value={need.unix_deadline !== undefined && (need.unix_deadline > 0) && need.show_deadline}
                        change={makeChange<boolean>('show_deadline')}
                        title='Отображать срок'
                        disabled={disabled || !need.unix_deadline}
                    />
                    {need.show_deadline ? '1' : '0'}
                </div>

                <label htmlFor="unix_deadline">Дата события:</label>
                <div>
                    <DateTime unix={need.event_unix} change={makeChange<number | undefined>('event_unix')}/>
                </div>
                <label htmlFor="unix_deadline">Длительность события (минут):</label>
                <Number value={need.event_interval && Math.round(need.event_interval / 60)}
                    change={changeEventInterval()}
                    disabled={disabled || !need.event_unix}
                />

                <span></span>
                <span className={cl.info}>
                    {need.event_unix ? dtToTimeDateString(need.event_unix) : ''}
                    {' - '}
                    {need.event_unix && need.event_interval ? dtToTimeDateString(need.event_unix + need.event_interval) : ''}
                </span>

                <label>Потребности:</label>
                <span>{need.need_types_ids}</span>

                <label>Нужно собрать:</label>
                <Number value={need.money_goal}
                    change={makeChange<number|undefined>('money_goal')}
                    disabled={disabled}
                />

                <label>Уже собрано:</label>
                <Number value={need.money_collected}
                    change={makeChange<number|undefined>('money_collected')}
                    disabled={disabled || !need.money_goal}
                />

                <label htmlFor="title">Заголовок:</label>
                <textarea id="title" rows={2}
                    value={need.title}
                    onChange={makeChangeHtml<string>('title')}></textarea>

                <label htmlFor="brief_desc">Краткое описание:</label>
                <textarea id="brief_desc" rows={6}
                    value={need.brief_desc}
                    onChange={makeChangeHtml<string>('brief_desc')}></textarea>

                <label htmlFor="full_desc">Полное описание:</label>
                <textarea id="full_desc" rows={10}
                    value={need.full_desc}
                    onChange={makeChangeHtml<string>('full_desc')}></textarea>
                <label htmlFor="image">Изображение (600x677):</label>
                <ImageFile filename={need.image} change={onBase64} />

            </form>
            <div style={{
                margin: '0 0 0 auto',
                maxWidth: 400,
                paddingTop: 32,
            }}>
                <DuetButtons
                    first={{
                        type: 'transparent',
                        action: cancel,
                        content: 'Отмена',
                    }}
                    second={{
                        type: 'success',
                        action: save,
                        content: 'Сохранить',
                    }}
                    disabled={disabled}
                />
            </div>
            <pre>
                {
                    JSON.stringify(need, null, '   ')
                }
            </pre>
        </div>
    )
}
