import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import cl from './index.module.scss'
import { Need, Temple, TempleProject } from '../../../core/types'
import { dtToTimeDate, dtToUserDateString } from '../../../core/helperTime'
import { Button } from '../../From/Button'
import { config } from '../../../config'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../routes/urls'
import { NeedParam } from './NeedParam'


type Props = {
    need: Need
    temples: Record<number, Temple>
    projects: Record<number, TempleProject>
}

export const OneNeed: React.FunctionComponent<Props> = ({ need, temples, projects }) => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate(urls.need(`${need.id}`))
    }
    return (
        <>
            <div className={cl.need}>
                <NeedContent need={need} temples={temples} projects={projects}
                    button={
                        need.unix_closed ? (
                            <div className={cl.need__activity_ended}>Завершён</div>
                        ) : (
                            <div className={cl.need__activity_btn}>
                                <Button type='default'
                                    content='Хочу помочь'
                                    click={onClick}
                                />
                            </div>
                        )
                    }/>
            </div>
        </>
    )
}

type PropsNeedContent = {
    need: Need
    temples: Record<number, Temple>
    projects: Record<number, TempleProject>
    button?: ReactNode
    children?: ReactNode
    showText?: 'brief' | 'full'
}
export const NeedContent: React.FunctionComponent<PropsNeedContent> = ({ need, temples, projects, button, showText, children }) => {
    const [goal, setGoal] = useState(null as ReactNode | null)
    const [dateEnd, setDateEnd] = useState(null as ReactNode | null)
    const [event, setEvent] = useState(null as ReactNode | null)
    useEffect(() => {
        if (need.money_goal && need.money_goal > 0) {
            // setGoal(<Goal money={need.money_goal}/>)
            setGoal(<NeedParam title='Цель'
                value={`${moneyString(need.money_goal)}`}
                additional_class={cl['need__param--gold']}
            />)
        }
        if (need.show_deadline && need.unix_deadline && need.unix_deadline > 0) {
            setDateEnd(<NeedParam title='Дата окончания' value={dtToUserDateString(need.unix_deadline)}/>)
        }
        if (need.event_unix && need.event_unix > 0) {
            setEvent(<NeedParam title='Дата/время события'
                value={eventDate(need.event_unix, need.event_interval)}/>)
        }
    }, [])
    return (
        <>
            <div className={cl.need__head}>
                <span className={cl.need__head_date}>{dtToUserDateString(need.unix_placed)}</span>
                <span className={cl.need__head_temple}>{
                    need.project_id
                        ? <>{temples[need.temple_id].title} → <span>{projects[need.project_id].title}</span></>
                        : temples[need.temple_id].title
                }</span>
            </div>
            <div className={cl.need__title}>
                {need.title}
            </div>
            <div>
                {children}
            </div>
            <div className={cl.need__all_params}>
                {goal}
                {dateEnd}
                {event}
            </div>
            <div className={cl.need__brief}>
                {
                    need.image
                        ? <div className={cl.need__brief_img}>
                            <img src={`${config.api_url}/api/images/${need.image}`} alt={need.title} />
                        </div> : null
                }
                {
                    showText == 'full'
                        ? <>{need.full_desc.split('\n').map((one, i, arr) => {
                            return <p key={i}
                                className={cl.content_p + ' ' + (i + 2 === arr.length ? cl.content_p_pred : '')}
                            >{one}</p>
                        })}</>
                        : <p className={cl.need__brief_text}>{need.brief_desc}</p>
                }
                <div className={cl.need__brief_clear}></div>
            </div>
            <div className={cl.need__activity}>
                {
                    need.money_goal
                        ? (
                            <MoneyCollect goal={need.money_goal} collected={need.money_collected} />
                        ) : <span/>
                }
                { button ? button : null }
            </div>
        </>
    )
}

function eventDate(dts: number, interval?: number): string | ReactNode {
    const dtStart = dtToUserDateString(dts)
    const info = dtToTimeDate(dts)
    if (!interval) {
        return `${dtStart} ${info.time}`
    }
    const endInfo = dtToTimeDate(dts + interval)
    if (info.date == endInfo.date) {
        return <span>
            {dtStart} <span style={{ whiteSpace: 'nowrap' }}>{info.time} - {endInfo.time}</span>
        </span>

    }
    const dtEnd = dtToUserDateString(dts + interval)
    return <>
        <span>{dtStart} {info.time}</span> - <span>
            <span style={{ whiteSpace: 'nowrap' }}>{dtEnd}</span> {endInfo.time}
        </span>
    </>

}
function MoneyCollect(props: { goal: number, collected?: number}) {
    const r = useRef(null)
    const percent = useMemo(() => {
        if (!props.collected) {
            return '0%'
        }
        return `${Math.round(props.collected / props.goal * 100)}%`
    }, [props.goal, props.collected])

    return (
        <div className={cl.collected}>
            <div className={cl.collected__bg}></div>
            <div ref={r} className={cl.collected__full}
                style={{ width: percent }} ></div>
            <div className={cl.collected__empty}>{percent} {moneyString(props.collected || 0)}</div>

        </div>
    )
}

function moneyString(money: number): string {
    return money.toLocaleString('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
}