import React, { FunctionComponent, useEffect, useState } from 'react'
import { useAsyncError } from 'react-router-dom'
import { RequestError } from './SmartError'
import { parseError } from '../../core/errors'



export const AwaitError: FunctionComponent = () => {
    const error = useAsyncError()
    const [err, setErr] = useState(undefined as RequestError | undefined)
    useEffect(() => {
        if (!error) {
            setErr(undefined)
        } else {
            console.error(error)
            setErr(parseError(error))
        }
    }, [error])
    if (!err) {
        return null
    }
    return (
        <div className="prose m-4">
            <h2 className="card-title">Oops!</h2>
            <div className="prose text-xs">
                <p className="pb-0 mb-0">Sorry, an unexpected error has occurred.</p>
                <p className="italic text-xs pt-0 mt-0">
                    <span className="font-bold text-base pr-3">{err.error}</span> <span>{err.message}</span>
                </p>
                {
                    err.detail ? (
                        <p className="text-sm">{err.detail}</p>
                    ) : undefined
                }
            </div>
        </div>
    )
}
