import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { urls } from './urls'
import { LoginPage, loader as loaderAdminLogin } from '../pages/admin/LoginPage'
import { authorizedLoader, smartLoader } from './loaders'
import { DashboardPage } from '../pages/admin/DashboardPage'
import { logoutLoader } from '../pages/admin/LogoutPage'
import { MainPage, mainLoader } from '../pages/MainPage'
import { NeedPage, needLoader } from '../pages/NeedPage'


export default createBrowserRouter([
    {
        path: urls.main,
        element: <MainPage />,
        loader: mainLoader,
    }, {
        path: urls.need(),
        element: <NeedPage />,
        loader: needLoader,
    }, {
        path: urls.needSended(),
        element: <NeedPage />,
        loader: needLoader,
    }, {
        path: urls.adminLogin,
        element: <LoginPage/>,
        loader: loaderAdminLogin,
    }, {
        path: urls.adminDashboard,
        element: <DashboardPage/>,
        loader: smartLoader(undefined, authorizedLoader),
    }, {
        path: urls.adminLogout,
        loader: logoutLoader,
    },
])