import React, { useEffect, useState } from 'react'
import { curTime } from '../../core/helperTime'
import { leadZero } from '../../core/helper'
// import cl from './index.module.scss';


type Props = {
    toTs: number
    stepSec?: number
    complete: () => void
}

export const Timer: React.FunctionComponent<Props> = (props) => {
    const [sec, setSec] = useState(props.toTs - curTime())
    useEffect(() => {
        const step = props.stepSec || 0.1
        if (sec - step <= 0) {
            props.complete()
        } else {
            setTimeout(() => {
                setSec(one => one - step)
            }, step * 1000)
        }
    }, [sec])
    if (sec <= 0) {
        return <></>
    }
    return (
        <>{secToTime(sec)}</>
    )
}

function secToTime(n: number) {
    const m = Math.floor(n / 60)
    const s = Math.round(n - m * 60)
    return `${leadZero(m, 2)}:${leadZero(s, 2)}`
}