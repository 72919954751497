import { leadZero } from './helper'

export const HOUR_SEC = 60 * 60
export const DAY_SEC = 60 * 60 * 24



export function dtToUnix(dt: Date, onlySec?: boolean): number {
    const t = dt.getTime() / 1000
    if (onlySec) {
        return Math.floor(t)
    }
    return t
}

export function curTime(onlySec?: boolean): number {
    return dtToUnix(new Date(), onlySec)
}

export function dtDate(dt: number): Date {
    return new Date(Math.round(dt * 1000))
}


export function dtToDateString(uts: number): string {
    const dt = dtDate(uts)
    return `${dt.getFullYear()}-${leadZero(dt.getMonth() + 1)}-${leadZero(dt.getDate())}`
}

export function dtToTimeString(dt: number): string {
    const d = dtDate(dt)
    return `${leadZero(d.getHours())}:${leadZero(d.getMinutes())}`
}

export function dtToTimeDate(dts: number) {
    const dt = dtDate(dts)
    const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    return {
        time: `${leadZero(dt.getHours())}:${leadZero(dt.getMinutes())}`,
        date: `${leadZero(dt.getDate())}.${leadZero(dt.getMonth() + 1)}`,
        year: `${dt.getFullYear()}`,
        day: days[dt.getDay()],
    }
}

export function dtToUserDateString(dts: number): string {
    const dt = dtDate(dts)
    const monthNames = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
    ]
    const month = monthNames[dt.getMonth()]
    return `${leadZero(dt.getDate())} ${month} ${dt.getFullYear()}`
}

export function dtToTimeDateString(dts: number): string {
    const info = dtToTimeDate(dts)
    return `${info.date}.${info.year} ${info.time}`
}

export function strToHoursAndMinutes(time: string): [number, number] {
    return time.split(':').map(Number) as [number, number]
}

