import React, { ReactNode } from 'react'
import cl from './index.module.scss'


type Props = {
    title: string
    value: string | ReactNode
    additional_class?: string
}

export const NeedParam: React.FunctionComponent<Props> = (props) => {
    return (
        <div className={cl.need__param + ' ' + (props.additional_class || '')}>
            <div className={cl.need__param_title}>{props.title}</div>
            <div className={cl.need__param_value}>{props.value}</div>
        </div>
    )
}
