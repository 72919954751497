import React, { useState } from 'react'
import { Dashboard } from '../../../core/dashboard'
import { Need, createEmptyNeed } from '../../../core/types'
import { NeedEdit } from '../NeedEdit'
// import cl from './index.module.scss';


type Props = {
    data?: Dashboard
    change: (data: Dashboard) => void
    need?: Need
    setNeed: (n?: Need) => void
}


export const Needs: React.FunctionComponent<Props> = (props) => {
    const addNew = () => {
        props.setNeed(createEmptyNeed())
    }
    if (props.need && props.data) {
        return <NeedEdit need={props.need} setNeed={props.setNeed as any} dict={props.data}/>
    }

    return (
        <div>
            New Component Needs
            <div>
                {
                    props.data?.needs.map(one=>{
                        return (
                            <div key={one.id} onClick={() => { props.setNeed(one)}}>
                                - {one.title}
                            </div>
                        )
                    })
                }
            </div>
            <button onClick={addNew}>add new</button>
        </div>
    )
}
