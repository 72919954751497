import React, { useMemo, useState } from 'react'
import cl from './index.module.scss'
import { Await, LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { ClientDashboard, apiNeeds } from '../../core/apiNeeds'
import { AwaitError } from '../../Component/Error/AwaitError'
import { OneNeed } from '../../Component/Client/OneNeed'
import { Filter } from '../../Component/Client/Filter'
import { loadProjects, loadTemples } from '../../core/api'
import { Layout } from '../Layout'
import { TemplateLoading } from '../../Component/Client/HeadLogo'


export const MainPage: React.FunctionComponent = (props) => {
    const data = useLoaderData() as {
        needs: Promise<ClientDashboard>
    }
    return (
        <Layout>
            <React.Suspense
                fallback={<TemplateLoading/>}
            >
                <Await
                    resolve={data.needs}
                    errorElement={<AwaitError />}
                >
                    {(data) => <Content data={data}/>}
                </Await>
            </React.Suspense>
        </Layout>
    )
}

const Content: React.FC<{ data: ClientDashboard }> = ({ data }) => {
    const [needTypeSelect, setNeedTypeSelect] = useState(0)
    const temples = useMemo(() => {
        return loadTemples(data.temples)
    }, [data.temples])
    const projects = useMemo(() => {
        return loadProjects(data.projects)
    }, [data.projects])
    const needs = useMemo(() => {
        if (needTypeSelect == 0) {
            return data.needs
        }
        if (needTypeSelect == -1) {
            return data.needs.filter(one => one.unix_closed)
        }
        return data.needs.filter(one => one.needTypesIds.includes(needTypeSelect))
    }, [data.needs, needTypeSelect])

    return (
        <Layout.Main>
            <Layout.Main.Title>
                <h1>Помощь храму</h1>
            </Layout.Main.Title>
            <Filter data={data} selected={needTypeSelect} changeSelected={setNeedTypeSelect}/>
            <div className={cl.records}>
                {
                    needs.map(one => <OneNeed key={one.id}
                        need={one}
                        temples={temples}
                        projects={projects}
                    />)
                }
            </div>
            {/* {JSON.stringify(data, null, '  ')} */}
        </Layout.Main>
    )
}

export async function mainLoader(req: LoaderFunctionArgs) {
    return defer({
        needs: apiNeeds(),
    })
}
