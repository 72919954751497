import axios, { AxiosRequestConfig } from 'axios'
import { config } from '../config'
import { NeedType, Temple, TempleProject } from './types'


export type ApiError = {
    error: number
    message: string
    detail?: string
}

export function ApiRequest<T = any>(option: AxiosRequestConfig): Promise<T> {
    option.url = `${config.api_url}${option.url}`
    option.headers = option.headers || {}
    const session_uuid = localStorage.getItem('session_uuid')
    if (session_uuid) {
        option.headers[config.header_token] = session_uuid
    }
    console.info('request to', option)
    return new Promise<T>((resolve, reject) => {
        axios(option).then(result => {
            if (result.status === 200) {
                resolve(result.data)
            } else {
                reject({
                    error: result.status,
                    message: `${result.statusText}`,
                })
            }
        }).catch(err => {
            console.info(`error from ${option.url}:`, err)
            if (err.response) {
                reject({
                    error: err.response ? err.response.status : 0,
                    message: `${err.message}`,
                    detail: err.response.data.error_message,
                })
            } else if (err.request) {
                reject({
                    error: err.response ? err.response.status : -1,
                    message: err.message || `${err.request}`,
                })
            } else {
                reject({
                    error: err.response ? err.response.status : -2,
                    message: err.message,
                })
            }
        })
    })
}



export function loadTemples(temples: Temple[]): Record<number, Temple> {
    return temples.reduce((res, one) => {
        res[one.id] = one
        return res
    }, {} as Record<number, Temple>)
}

export function loadProjects(projects: TempleProject[]): Record<number, TempleProject> {
    return projects.reduce((res, one) => {
        res[one.id] = one
        return res
    }, {} as Record<number, TempleProject>)
}

export function loadNeedTypes(needTypes: NeedType[]): Record<number, NeedType> {
    return needTypes.reduce((res, one) => {
        res[one.id] = one
        return res
    }, {} as Record<number, NeedType>)
}