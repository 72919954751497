import { ApiRequest } from './api'
import { Need } from './types'

export function apiSaveNeedType(id: number, name: string, priority: number) {
    return ApiRequest({
        method: 'post',
        url: '/api/admin/need-type/save',
        data: {
            id,
            name,
            priority,
        },
    })
}


export function apiSaveNeed(need: Need) {
    return ApiRequest({
        method: 'post',
        url: '/api/admin/need/save',
        data: need,
    })
}

