import React, { useEffect, useRef, useState } from 'react'
import cl from './index.module.scss'
import { Link } from 'react-router-dom'
import { urls } from '../../../routes/urls'
import { Needs } from '../../../Component/Admin/Needs'
import { Dashboard, apiDashboard } from '../../../core/dashboard'
import { NeedTypes } from '../../../Component/Admin/NeedTypes'
import { Need } from '../../../core/types'


type StageType = 'user_wait' | 'load' | 'loading'
export const DashboardPage: React.FunctionComponent = () => {
    const [data, setData] = useState(undefined as Dashboard | undefined)
    const [need, setNeed] = useState(undefined as Need | undefined)
    const [stage, setStage] = useState('load' as StageType)
    const [disabled, setDisabled] = useState(false)

    const v = useRef(0)
    useEffect(() => {
        if (need === undefined) {
            setStage('load')
        }
    }, [need])
    useEffect(() => {
        if (stage !== 'load') {
            return
        }
        setStage('loading')
        apiDashboard().then(res => {
            setData(res)
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setStage('user_wait')
        })
    }, [stage])
    const selectNeedTypes = (n: number[]) => {
        setNeed(need => {
            if (!need) return need
            return {
                ...need,
                need_types_ids: `,${n.join(',')},`,
            }
        })
    }
    const change = (data: Dashboard) => {
        setData({ ...data })
    }
    return (
        <div className={cl.dashboard}>
            <div>
            New Component DashboardPage
                <div><Link to={urls.adminLogout}>Logout</Link></div>
            </div>
            <div className={cl.dashboard__view}>
                <div className={cl.dashboard__view__needs}>
                    <Needs data={data} change={change} need={need} setNeed={setNeed}/>
                </div>
                <div>
                    <NeedTypes data={data} change={change}
                        disabled={disabled} setDisabled={setDisabled}
                        select={need ? selectNeedTypes : undefined}
                        selected={
                            need
                                ? need.need_types_ids.split(',')
                                    .filter(one => one.length > 0)
                                    .map(one => parseInt(one))
                                : undefined}
                    />
                </div>
            </div>

        </div>
    )
}

