import React, { useEffect, useState } from 'react'
import cl from './index.module.scss'


type Props = {
    t?: boolean
}

export const HeadLogo: React.FunctionComponent<Props> = (props) => {
    return (
        <img src='/img/top_head.svg' height={'100%'}/>
    )
}

export const Loading: React.FC = () => {
    return (
        <div className={cl.wait}>
            <img src='/img/star.svg' height={120} />
        </div>
    )
}

export const TemplateLoading: React.FC = () => {
    return (
        <div className={cl.template_loading}>
            <div className='font-monomakh' style={{ paddingLeft: 30, paddingBottom: 90 }}>
                Загрузка...
            </div>
            <Loading/>
        </div>
    )
}


export const WaitLogo: React.FunctionComponent<{ sec: number, complete: () => void }> = (props) => {
    const [sec, setSec] = useState(props.sec)
    useEffect(() => {
        if (sec - 0.1 <= 0) {
            props.complete()
        } else {
            setTimeout(() => {
                setSec(one => one - 0.1)
            }, 100)
        }
    }, [sec])

    return (
        <div className={cl.wait}>
            <div className={cl.wait__logo}>
                <HeadLogo />
                <img className={cl.wait__logo_circle} src='/img/circle.svg' height={'100%'} />
            </div>
            <div>
                Получение ответа,<br/>через {sec.toFixed(1)} сек.
            </div>

        </div>
    )
}