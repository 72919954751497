import { ApiRequest } from './api'
import { curTime } from './helperTime'

export async function apiAuth(login: string, pass: string) {
    return ApiRequest({
        method: 'post',
        url: '/api/auth',
        data: {
            login,
            pass,
        },
    })
}

export function apiLive() {
    return ApiRequest<{status: string, unix_expired?: number}>({
        method: 'post',
        url: '/api/live',
    })
}

export function apiLogout() {
    return ApiRequest<{status: string}>({
        method: 'post',
        url: '/api/logout',
    })
}


export type authCheckErrorType = '' | 'no_session' | 'session_expired' | 'soon_session_expired'
export function checkAuthorized(): [boolean, authCheckErrorType] {
    const uuid = localStorage.getItem('session_uuid')
    const expired = localStorage.getItem('session_expired')
    if (!uuid || !expired) {
        return [false, 'no_session']
    }
    const tsExpired = parseInt(expired)
    if (!tsExpired) {
        return [false, 'no_session']
    }
    if (curTime() > tsExpired) {
        return [false, 'session_expired']
    }
    if (curTime() + 60 * 60 * 20 > tsExpired) {
        return [false, 'soon_session_expired']
    }
    return [true, '']
}

export function setInAdvanceRequestTime(unix_time: number) {
    localStorage.setItem('session_in_advance_request', `${unix_time}`)
}

export function getInAdvanceRequestTime(): number | undefined {
    const t = localStorage.getItem('session_in_advance_request')
    if (t == null) {
        return undefined
    }
    const x = parseInt(t)
    return !x ? undefined : x
}

export function setAuthorized(data: { token: string, unix_expired: number }) {
    localStorage.setItem('session_uuid', data.token)
    setExpiredTime(data.unix_expired)
}

export function setExpiredTime(unix_expired: number) {
    localStorage.setItem('session_expired', `${unix_expired}`)
}

export function logout() {
    return apiLogout().then(res => {
        localStorage.removeItem('session_uuid')
        localStorage.removeItem('session_expired')
        localStorage.removeItem('session_in_advance_request')
    })
}