import { ApiRequest } from './api'
import { Dashboard } from './dashboard'
import { Need } from './types'

type ClientNeed = Need & {
    needTypesIds: number[]
}
export type ClientDashboard = Omit<Dashboard, 'needs'> & {
    needs: ClientNeed[]
}
export type ClientNeedDashboard = Omit<Dashboard, 'needs'> & {
    need: ClientNeed
}

export function apiNeeds() {
    return ApiRequest<ClientDashboard>({
        method: 'post',
        url: '/api/needs',
    }).then(res => {
        res.needs = res.needs.map(one => {
            one.needTypesIds = one.need_types_ids.split(',').filter(one => one.length > 0).map(one => parseInt(one))
            return one
        })
        return res
    })
}

export function apiOneNeed(needId: number) {
    return ApiRequest<ClientNeedDashboard>({
        method: 'post',
        url: `/api/need/${needId}`,
    }).then(res => {
        res.need.needTypesIds = res.need.need_types_ids.split(',').filter(one => one.length > 0).map(one => parseInt(one))
        return res
    })
}

export type DonationAnswer = {
    abbot_name: string
    name: string
    phone: string
    wait: number
}


export function apiSendNeedDonationRequest(needId: number, needsIds: number[], text: string) {
    return ApiRequest<DonationAnswer>({
        method: 'post',
        url: `/api/need/${needId}/donation-request`,
        data: {
            need_ids: needsIds,
            text,
        },
    })
}