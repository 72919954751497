import React, { useCallback, useEffect, useState } from 'react'
// import cl from './index.module.scss';


type Props = {
    value?: number
    change: (value?: number) => void
    disabled?: boolean
}

export const Number: React.FunctionComponent<Props> = (props) => {
    const [formId, setFormId] = useState(`${Math.random()}`)
    const [focused, setFocused] = useState(false)
    const [value, setValue] = useState(toString(props.value))
    useEffect(() => {
        setFormId(`${Math.random()}`)
        setFocused(false)
    }, [])
    useEffect(() => {
        if (!focused) {
            setValue(toString(props.value))
        }
    }, [focused, props.value, setValue])
    const callback = useCallback((v: string) => {
        setValue(v)
        const n = parseFloat(v)
        if (isNaN(n)) {
            props.change(undefined)
        } else {
            props.change(n)
        }
    }, [props.change, setValue])
    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
        callback(e.target.value)
    }
    const focus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true)
    }
    const blur = (e: React.FocusEvent<HTMLInputElement>) => {
        callback(e.target.value)
        setFocused(false)
    }
    const input = (e: React.FocusEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }
    return (
        <input
            id={formId}
            type="number"
            value={value}
            onChange={change}
            onFocus={focus}
            onBlur={blur}
            onInput={input}
            disabled={props.disabled === true}
        />
    )
}

function toString(val?: number): string {
    if (val === undefined || isNaN(val)) {
        return ''
    }
    return `${val}`
}