import { curTime } from './helperTime'

export type Temple = {
    id: number;
    name: string;
    title: string;
    image?: string;
    short_desc: string;
    full_desc: string;
    abbot_name: string
    priority: number;
}

export type TempleProject = {
    id: number;
    temple_id: number;
    name: string;
    title: string;
    id_enabled: boolean;
    priority: number;
}

export type NeedType = {
    id: number;
    name: string;
    title: string;
    priority: number;
}

export type PeriodType = {
    id: number;
    name: string;
    title: string;
    interval: string;
}

export type Need = {
    id: number;
    temple_id: number;
    project_id: number;
    need_types_ids: string
    unix_placed: number;
    unix_deadline?: number;
    show_deadline: boolean;
    title: string;
    brief_desc: string;
    full_desc: string;
    image?: string
    money_goal?: number;
    money_collected?: number;
    event_unix?: number;
    event_interval?: number;
    period_id: number;
    is_displayed: boolean;
    unix_closed?: number;
    priority: number;
}


export function createEmptyNeed(): Need {
    return {
        id: 0,
        temple_id: 1,
        project_id: 0,
        need_types_ids: ',,',
        unix_placed: curTime(true),
        show_deadline: false,
        title: '',
        brief_desc: '',
        full_desc: '',
        money_goal: undefined,
        money_collected: undefined,
        event_unix: undefined,
        event_interval: undefined,
        period_id: 0,
        is_displayed: false,
        unix_closed: undefined,
        priority: 0,
    }
}