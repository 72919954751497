import React from 'react'
// import cl from './index.module.scss';


type Props = {
    filename?: string
    base64?: string
    change: (base64?: string) => void
}

export const ImageFile: React.FunctionComponent<Props> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            props.change(undefined)
            return
        }
        const file = e.target.files[0]
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            alert('Пожалуйста, выберите изображение PNG или JPEG.')
            return
        }
        const reader = new FileReader()

        reader.onloadend = () => {
            if (reader.result) {
                if (typeof (reader.result) === 'string') {
                    props.change(reader.result)
                }
            }
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }
    return (
        <div>
            {props.filename}
            {props.base64}
            <input type="file" id="image" accept="image/png, image/jpeg" onChange={onChange}/>
        </div>
    )
}
