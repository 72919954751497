/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cl from './index.module.scss'
import { Await, LoaderFunctionArgs, defer, useLoaderData, useNavigate } from 'react-router-dom'
import { ClientNeedDashboard, apiOneNeed, apiSendNeedDonationRequest } from '../../core/apiNeeds'
import { ApiError, loadNeedTypes, loadProjects, loadTemples } from '../../core/api'
import { AwaitError } from '../../Component/Error/AwaitError'
import { NeedContent } from '../../Component/Client/OneNeed'
import { Button } from '../../Component/From/Button'
import { NeedParam } from '../../Component/Client/OneNeed/NeedParam'
import { Layout } from '../Layout'
import { Link } from 'react-router-dom'
import { urls } from '../../routes/urls'
import { Need } from '../../core/types'
import { TemplateLoading, WaitLogo } from '../../Component/Client/HeadLogo'
import { curTime } from '../../core/helperTime'
import { Timer } from '../../Component/Client/Timer'


export const NeedPage: React.FunctionComponent = () => {
    const data = useLoaderData() as {
        data: Promise<ClientNeedDashboard>
        isSended: Promise<boolean>
    }
    return (
        <Layout>
            <React.Suspense
                fallback={ <TemplateLoading/>}
            >
                <Await
                    resolve={Promise.all([data.data, data.isSended])}
                    errorElement={<AwaitError />}
                >
                    {([data, isSended]) => <Content data={data} isSended={isSended}/>}
                </Await>
            </React.Suspense>
        </Layout>
    )
}

const Content: React.FC<{ data: ClientNeedDashboard, isSended: boolean }> = ({ data, isSended }) => {
    const temples = useMemo(() => {
        return loadTemples(data.temples)
    }, [data.temples])
    const projects = useMemo(() => {
        return loadProjects(data.projects)
    }, [data.projects])
    const need: Need = useMemo(() => {
        const text = data.need.full_desc.replaceAll('[Имя иерея]', temples[data.need.temple_id].abbot_name)
        return {
            ...data.need,
            full_desc: text,
        }
    }, [data.need, temples])

    return (
        <Layout.Main>
            <Layout.Main.Title>
                <h1>Помощь храму</h1>
            </Layout.Main.Title>
            <Layout.Main.Content>
                <div className={cl.back_btn}>
                    <Link to={urls.main} >Назад</Link>
                </div>
                <NeedContent need={need} projects={projects} temples={temples} showText='full'>
                    <UserSelect data={data} isSended={isSended}/>
                </NeedContent>
            </Layout.Main.Content>
        </Layout.Main>
    )
}


type StageType = 'user_wait' | 'send'
const UserSelect: React.FC<{ data: ClientNeedDashboard, isSended: boolean }> = ({ data, isSended }) => {
    return (
        <>
            {
                isSended
                    ? <DonationWait data={data}/>
                    : <DonationRequest data={data}/>
            }
        </>
    )
}

type DonationWaitProps = {
    data: ClientNeedDashboard
}
const DonationWait: React.FC<DonationWaitProps> = ({ data }) => {
    const [helpsIds, setHelpsIds] = useState({} as Record<number, boolean>)
    const [text, setText] = useState('')
    const [waitToTs, setWaitToTs] = useState(100)
    const [info, setInfo] = useState(undefined as {
        abbotName: string
        name: string
        phone: string
        wait: number
        ts: number
    } | undefined)
    useEffect(() => {
        const str = localStorage.getItem(`request${data.need.id}`)
        if (!str) {
            return
        }
        const json = JSON.parse(str)
        setHelpsIds(json['helpsIds'] || {})
        setText(json['text'])
        setInfo({
            name: json['name'],
            abbotName: json['abbotName'],
            phone: json['phone'],
            wait: json['wait'],
            ts: json['ts'],
        })
        setWaitToTs(json['ts'] + json['wait'])
    }, [])
    const compete = () => {
        console.log('compete')
    }
    return (
        <>
            <div className='normal_text'>
                <p>Дорогие прихожане и благотворители!</p>
                <p>
                    Ваше сообщение передано ответственному сотруднику нашего храма.
                </p>
            </div>
            <div className={cl.minus_h_border}>
                <p>
                    <span>{info?.name}</span> <WaitText waitToTs={waitToTs}/>
                </p>
                <p>
                    Мы рады, что вы готовы помочь нашему храму и нашей общине. Ваша помощь очень важна.
                </p>
                <p>
                    Пожалуйста, свяжитесь с нами по следующему номеру телефона: <a href={`tel:${info?.phone}`}>{info?.phone}</a>. Мы готовы обсудить все детали вашей помощи и ответить на все ваши вопросы.
                </p>
                <p>Спасибо вам за ваше сердце и вашу щедрость! Бог вас благословит!</p>
                <p>С уважением,<br/>
                    {info?.abbotName}</p>
            </div>
        </>
    )
}

function WaitText(props: {waitToTs?: number}) {
    const [ts, setTs] = useState(props.waitToTs)
    useEffect(() => {
        setTs(props.waitToTs)
    }, [props.waitToTs])
    const complete = () => {
        setTs(0)
    }
    if (!ts || ts - curTime() <= 0) {
        return <>
            ждёт вашего звонка.
        </>
    }
    return (
        <>
            уже через <span><Timer toTs={ts} stepSec={1} complete={complete} /></span> освободится и будет ждать вашего звонка.
        </>
    )
}


type DonationRequestProps = {
    data: ClientNeedDashboard
}
const DonationRequest: React.FC<DonationRequestProps> = ({ data }) => {
    const navigate = useNavigate()
    const [stage, setStage] = useState('user_wait' as StageType)
    const [helpsIds, setHelpsIds] = useState({} as Record<number, boolean>)
    const [text, setText] = useState('')
    const [err, setErr] = useState('')
    const needTypes = useMemo(() => {
        return loadNeedTypes(data.need_types)
    }, [data.projects])
    const disabled = useMemo(() => {
        const is = text.length > 0 || Object.keys(helpsIds)
            .map(one => helpsIds[one as any])
            .some(one => one === true)
        return !is || stage !== 'user_wait'
    }, [text, helpsIds, stage])
    const changeText = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value)
    }, [])
    const clickNeedType = (id: number) => {
        if (helpsIds[id] === true) {
            helpsIds[id] = false
        } else {
            helpsIds[id] = true
        }
        setHelpsIds({ ...helpsIds })
    }
    const send = () => {
        setStage('send')
        setErr('')
        const ids = Object.keys(helpsIds).filter(one => helpsIds[one as any]).map(one => parseInt(one))
        apiSendNeedDonationRequest(data.need.id, ids, text).then(res => {
            localStorage.setItem(`request${data.need.id}`, JSON.stringify({
                helpsIds,
                text,
                phone: res.phone,
                name: res.name,
                abbotName: res.abbot_name,
                wait: res.wait,
                ts: curTime(),
            }))
        }).catch(err => {
            setErr(JSON.stringify(err))
            setStage('user_wait')
        })
    }
    const complete = () => {
        navigate(urls.needSended(`${data.need.id}`), {
            replace: true,
        })
        setStage('user_wait')
    }
    return (
        <>
            <div className='normal_text'>
                <p>Дорогие прихожане и благотворители!</p>
                <p>Спасибо вам за ваше желание помочь нашему храму!
Мы рады, что вы готовы поддержать нас и нашу миссию. Ваша помощь очень важна для нас и наших прихожан.</p>
                <p>
                    На этой странице вы можете выбрать способ,
                который вам больше подходит, и мы будем благодарны за любую помощь нашему храму.
                </p>

                {/* <p>Спасибо вам за ваше сердце и вашу щедрость! Бог вас благословит!</p> */}
            </div>
            <div className={cl.minus_h_border}>
                {
                    stage === 'send'
                        ? <div className={cl.minus_h_border__wait}><WaitLogo sec={15} complete={complete} /></div>
                        : <>
                            <div>
                                <NeedParam title='Чем хотите помочь' value={
                                    <div className={cl.form__needs}>
                                        {
                                            data.need.needTypesIds.map(needTypeId => {
                                                return (
                                                    <Button
                                                        click={() => clickNeedType(needTypeId)}
                                                        type={helpsIds[needTypeId] ? 'gold' : 'border_info'}
                                                        size='small'
                                                        key={needTypeId}
                                                        content={needTypes[needTypeId].title}
                                                        disabled={stage !== 'user_wait'}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                }/>
                            </div>
                            <div>
                                <NeedParam title='Дополнительные комментарии или пожелания по вашей помощи'
                                    value={
                                        <textarea className={cl.form__comment + ' normal_text'}
                                            value={text}
                                            onChange={changeText}
                                            disabled={stage !== 'user_wait'}
                                        />
                                    }/>
                            </div>
                            <div className='normal_text'>
                                <p>
                    После нажатия кнопки «Отправить», ваше сообщение будет передано ответственному сотруднику нашего храма.
                    Чтобы уточнить детали, пожалуйста, свяжитесь с нами по номеру телефона который появится.</p>
                                <p>Спасибо вам за ваше сердце и вашу щедрость! Бог вас благословит!</p>
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <Button type='default' click={send} content='Отправить' disabled={disabled}/>
                            </div>


                            {
                                err.length > 0
                                    ? <div style={{ color: 'red' }}>
                            ERROR: {err}
                                    </div> : null
                            }
                        </>
                }

            </div>
        </>
    )
}


export async function needLoader(req: LoaderFunctionArgs) {
    const { needId: strNeedId } = req.params
    const needId = parseInt(strNeedId || '0')
    if (strNeedId === undefined || isNaN(needId) || needId == 0) {
        return Promise.reject({
            error: -99,
            message: 'wrong need',
            detail: 'Указанная потребность не найдена',
        } as ApiError)
    }
    const isSended = req.request.url.endsWith('sended')

    return defer({
        data: new Promise((resolve, reject) => {
            const s = curTime()
            apiOneNeed(needId).then(res => {
                const dur = curTime() - s
                if (dur > 900 || isSended) {
                    resolve(res)
                } else {
                    setTimeout(() => {
                        resolve(res)
                    }, 900 - dur)
                }
            }).catch(reject)
        }),
        isSended,
    })
}
