
export function isDev(): boolean {
    return process.env.NODE_ENV === 'development'
}

export function cls(...args: string[]): { className: string } {
    return {
        className: args.join(' '),
    }
}

export function leadZero(n: number | string, l: number = 2) {
    let str = ''
    if (typeof n === 'string') {
        str = n
    } else {
        str = n.toString()
    }
    while (str.length < l) {
        str = '0' + str
    }
    return str
}

