import React, { useMemo, useState } from 'react'
import cl from './index.module.scss'
import { Button } from '../../From/Button'
import { NeedType } from '../../../core/types'
import { ClientDashboard } from '../../../core/apiNeeds'


type Props = {
    data?: ClientDashboard
    selected: number
    changeSelected: React.Dispatch<React.SetStateAction<number>>
}

export const Filter: React.FunctionComponent<Props> = (props) => {
    const [showed, setShowed] = useState(false)
    const existNeedTypes = useMemo(() => {
        if (!props.data?.need_types) {
            return {} as Record<number, boolean>
        }
        return parseExistsNeedTypes(props.data)
    }, [props.data?.need_types])
    const needTypes: NeedType[] = useMemo(() => {
        if (!props.data?.need_types) {
            return []
        }
        const arr = [{
            id: 0,
            name: 'Все объявления',
            title: 'Все объявления',
            priority: 0,
        },
        ...props.data.need_types.filter(one => existNeedTypes[one.id] === true),
        ]
        const existClosed = props.data?.needs
            ? props.data?.needs.some(one => one.unix_closed && one.unix_closed > 0)
            : false
        if (existClosed) {
            arr.push({
                id: -1,
                name: 'Завершенные',
                title: 'Завершенные',
                priority: 1000,
            })
        }
        return arr

    }, [props.data?.need_types, existNeedTypes, props.data?.needs])
    const onClick = (needType: NeedType) => {
        props.changeSelected(needType.id)
    }
    return (
        <div className={cl.main}>
            <div className={cl.main__filter + ' ' + (showed ? cl['main__filter--showed'] : '')}>
                {
                    needTypes.filter(one => one.id == props.selected && !showed).map(one => <Button key={one.id}
                        type={one.id == props.selected ? 'gold' : 'border_default'}
                        click={() => onClick(one)} content={one.title} />)
                }
                {
                    needTypes.filter(one => showed || one.id !== props.selected).map(one => {
                        return <Button key={one.id}
                            type={one.id == props.selected
                                ? 'gold'
                                : one.id == -1
                                    ? 'border_accent'
                                    : 'border_default'
                            }
                            click={() => onClick(one)} content={one.title} />
                    })
                }
            </div>
            <div className={cl.main__show_hide}>
                <Button type='camouflage'
                    size='small'
                    click={() => setShowed(s => !s)}
                    content={showed ? 'Скрыть фильтры' : 'Показать все фильтры'}/>
            </div>
            <div className={cl.main__bottom}></div>
        </div>
    )
}

function parseExistsNeedTypes(data?: ClientDashboard): Record<number, boolean> {
    if (!data?.needs) {
        return {}
    }
    return data.needs.reduce((res, one) => {
        one.needTypesIds.forEach(id => {
            res[id] = true
        })
        return res
    }, {} as Record<number, boolean>)
}