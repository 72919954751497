import React, { useCallback, useEffect, useState } from 'react'
import cl from './index.module.scss'
import { cls } from '../../core/helper'
import { HeadLogo } from '../../Component/Client/HeadLogo'
import { curTime } from '../../core/helperTime'


interface Props {
    children: React.ReactNode;
}
const Title: React.FC<Props> = ({ children }) => {
    return <div className={cl['main__title']}>{children}</div>
}
const Content: React.FC<Props> = ({ children }) => {
    return <div className={cl['main__content']}>{children}</div>
}


interface MainProps {
    crop?: boolean
    children: React.ReactNode;
}
interface MainComponent {
    (props: MainProps): JSX.Element;
    Title: React.FC<LayoutProps>;
    Content: React.FC<LayoutProps>;
}

const main: MainComponent = ({ children, crop }: MainProps) => {
    return <main {...cls(
        cl.main,
    ) }>{children}</main>
}
main.Title = Title
main.Content = Content

type LayoutProps = {
    children: React.ReactNode
}

interface PageComponent {
    (props: LayoutProps): JSX.Element;
    Main: MainComponent;
}
const layout: PageComponent = (props) => {
    return (
        <div className={cl.page}>
            <FakeInfo />
            <div className={cl.page__head}>
                <HeadLogo />
            </div>
            {props.children}
            <div className={cl.page__footer}>
                <div className={cl.page__footer_copyright}>
                © 2024, <a href="https://t.me/hm_hm_hm">Александр Шестаков</a>
                </div>
            </div>
        </div>
    )
}
layout.Main = main

export const Layout = layout


function FakeInfo() {
    const [showInfo, setShowInfo] = useState(false)
    useEffect(() => {
        const s = localStorage.getItem('fake_info_read')
        if (!s) {
            setShowInfo(true)
            return
        }
        const ts = JSON.parse(s)
        if (ts + 60 * 60 < curTime()) {
            setShowInfo(true)
        }
    }, [])
    const onClick = useCallback(() => {
        setShowInfo(false)
        localStorage.setItem('fake_info_read', JSON.stringify(curTime()))
    }, [])
    return (
        <div className={cl.page__head_fake + ' ' + (!showInfo ? cl['page__head_fake--hide'] : '')} onClick={onClick}>
            <p>Добро пожаловать на наш портал-демонстрацию!
            Обратите внимание, что все представленные здесь потребности являются фиктивными и
            предназначены исключительно для демонстрации функционала портала.
            </p>
            <p>
                Надеюсь, что портал станет полезным инструментом
             для информирования прихожан о реальных потребностях храма и помощи нуждающимся. Спасибо за понимание!
            </p>
            <p style={{ textAlign: 'right' }}>Для скрытия нажмите на это сообщение!</p>
        </div>
    )
}