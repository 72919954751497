import React, { useCallback } from 'react'
import cl from './index.module.scss'
import { cls } from '../../../core/helper'
import { clickDelay, defaultDelay } from '../../../core/helperClick'


export type ButtonType = 'default' | 'camouflage' | 'gold' | 'border_default' | 'border_accent' | 'border_info'
type Props = {
    type: ButtonType
    content: string
    size?: 'normal' | 'small'
    click: () => void
    disabled?: boolean
}

export const Button: React.FunctionComponent<Props> = (props) => {
    const click = useCallback(clickDelay(defaultDelay, () => {
        props.click()
    }), [props.click])
    return (
        <button {...cls(
            cl.btn,
            cl[`btn--size-${props.size || 'normal'}`],
            cl[`btn--${props.type}`],
        )} onClick={click} disabled={props.disabled}
        >{props.content}</button>
    )
}
