import React, { useCallback, useEffect, useState } from 'react'
import { Dashboard } from '../../../core/dashboard'
import { ModalWithButton } from '../../Modal'
import { apiSaveNeedType } from '../../../core/apiNeed'
import { NeedType } from '../../../core/types'
import { Checkbox } from '../Forms/Checkbox'
// import cl from './index.module.scss';


type Props = {
    data?: Dashboard
    change: (data: Dashboard) => void
    disabled?: boolean
    setDisabled: (b: boolean) => void
    selected?: number[],
    select?: (selected: number[]) => void
}

type StageType = 'user_wait' | 'save' | 'saving'
export const NeedTypes: React.FunctionComponent<Props> = (props) => {
    const [stage, setStage] = useState('user_wait' as StageType)
    const [needType, setNeedType] = useState(undefined as NeedType | undefined)
    const [name, setName] = useState('')
    const [err, setErr] = useState('')
    useEffect(() => {
        if (!needType) {
            return
        }
        setName(needType.title)

    }, [needType])
    useEffect(() => {
        if (stage !== 'save') {
            return
        }
        if (needType === undefined || !props.data) {
            setStage('user_wait')
            return
        }
        setStage('saving')
        apiSaveNeedType(needType.id, name, needType.priority).then(res => {
            console.info(res)
            if (props.data === undefined) {
                throw 'aaaa'
            }
            if (needType.id == -1) {
                props.data.need_types.push({
                    id: res.id,
                    name: res.title,
                    title: res.title,
                    priority: needType.priority <= 0 ? props.data.need_types.length + 1 : needType.priority,

                })
            } else {
                props.data.need_types.map(one => {
                    if (one.id !== needType.id) {
                        return one
                    }
                    one.title = res.title
                    return one
                })
            }
            props.change({ ...props.data })
            setNeedType(undefined)
        }).catch((err) => {
            setErr(JSON.stringify(err, null, '  '))
        }).finally(() => {
            setStage('user_wait')
        })
    }, [stage])
    const changeNewName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        setErr('')
    }, [setName])
    const save = useCallback(() => {
        setStage('save')
        return false
    }, [setStage])
    const addNew = useCallback(() => {
        setNeedType({
            id: -1,
            name: '',
            title: '',
            priority: -1,
        })
    }, [setNeedType])
    const changeSelected = (id: number, select: boolean) => {
        if (props.select === undefined || props.selected === undefined) {
            return
        }
        let arr = props.selected
        if (select) {
            arr.push(id)
        } else {
            arr = arr.filter(one => one != id)
        }
        props.select([...arr])
    }
    const disabled = stage !== 'user_wait'
    return (
        <div style={{ flex: 0, minWidth: 200 }}>
            <h3>Потребности</h3>
            {
                props.data?.need_types.map(one => {
                    return (
                        <div key={one.id}
                        >
                            {
                                props.selected ? (
                                    <Checkbox
                                        title={one.title}
                                        value={props.selected.includes(one.id)}
                                        change={(s) => changeSelected(one.id, s)}
                                    />
                                ) : <span onClick={() => setNeedType(one)}>{one.title}</span>
                            }
                        </div>
                    )
                })
            }
            <ModalWithButton
                isShow={needType !== undefined}
                onClosed={() => setNeedType(undefined)}
                btn={{
                    type: 'transparent',
                    children: 'Добавить',
                    click: addNew,
                    disabled: props.disabled,
                }} duetBtn={{
                    first: 'cancel',
                    second: {
                        type: 'success',
                        content: 'Добавить',
                        action: save,
                    },
                }}
                disabled={disabled}
            >
                <div className='title'>Новая потребность:</div>
                <div style={{ paddingBottom: 24 }}>
                    <label htmlFor="">Имя:</label>
                    <input type='text' value={name} onChange={changeNewName}/>
                </div>
                <pre>{err}</pre>
            </ModalWithButton>
        </div>
    )
}
