import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { apiLive, checkAuthorized, getInAdvanceRequestTime, setExpiredTime, setInAdvanceRequestTime } from '../core/auth'
import { ApiError } from '../core/api'
import { urls } from './urls'
import { HOUR_SEC, curTime } from '../core/helperTime'


type Handler = (r: LoaderFunctionArgs) => Promise<LoaderFunctionArgs<any> | Response>
export function smartLoader(
    main?: (r: LoaderFunctionArgs) => Promise<Record<string, any>>,
    ...handlers: Handler[]
) {
    return async (r: LoaderFunctionArgs) => {
        if (!r.context) {
            r.context = {}
        }
        const req = await handlers.reduce(async (r, h) => {
            const req = await r
            if (req instanceof Response) {
                return req
            }
            return await h(req)
        }, Promise.resolve(r) as Promise<LoaderFunctionArgs<any> | Response>)
        if (req instanceof Response) {
            return req
        }
        return main ? main(req) : r.context
    }
}


export async function authorizedLoader(req: LoaderFunctionArgs) {
    const [isAuthorized, err] = checkAuthorized()
    async function live(in_advance?: boolean) {
        if (in_advance) {
            const last_request = getInAdvanceRequestTime()
            if (last_request && (last_request + HOUR_SEC > curTime())) {
                console.info('Пока не спрашиваем что там с session expired')
                return Promise.resolve(true)
            }
            setInAdvanceRequestTime(Math.floor(curTime()))
        }
        const res = await apiLive()
        if (res.unix_expired && res.unix_expired > 0) {
            setExpiredTime(res.unix_expired)
            return Promise.resolve()
        }
        return await Promise.reject(undefined)
    }
    if (!isAuthorized) {
        if (err === 'soon_session_expired') {
            live(true).then(_=>{}).catch(_=>{})
            return req
        } else if (err === 'session_expired') {
            return new Promise<LoaderFunctionArgs<any> | Response>((resolve, reject) => {
                live().then(() => {
                    resolve(req)
                }).catch(err => {
                    if (!err) {
                        reject({
                            error: 401,
                            message: 'Unauthorized',
                            details: 'Время действия авторизации истекло',
                        } as ApiError)
                    } else {
                        reject(err)
                    }
                })
            })
        } else {
            return redirect(urls.adminLogin)
        }
    }
    return req
}


