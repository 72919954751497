import React, { useCallback, useEffect, useRef, useState } from 'react'
// import cl from './index.module.scss';
import { dtDate, dtToDateString, dtToTimeDate, dtToTimeString, dtToUnix, strToHoursAndMinutes } from '../../../../core/helperTime'


type Props = {
    unix?: number
    change: (unix?: number) => void
    disabled?: boolean
}

export const DateTime: React.FunctionComponent<Props> = (props) => {
    const [info, setInfo] = useState(dtToTimeDate(props.unix || 0))
    useEffect(() => {
        setInfo(dtToTimeDate(props.unix || 0))
    }, [props.unix])
    const changeDate = (dts?: number) => {
        if (!dts) {
            props.change(undefined)
        } else {
            props.change(dts)
        }
    }
    return (
        <>
            <InputDate dt={props.unix} change={changeDate}
                disable={props.disabled === true}
            />
            <InputTime dt={props.unix} change={changeDate}
                disable={props.disabled === true}
            />
        </>
    )
}


type PropsDate = {
    dt?: number
    disable: boolean
    change: (dts?: number) => void
}

export const InputDate: React.FC<PropsDate> = (props) => {
    const [value, setValue] = useState(props.dt ? dtToDateString(props.dt) : '')
    const r = useRef(null as HTMLInputElement | null)
    useEffect(() => {
        setValue(props.dt ? dtToDateString(props.dt) : '')
    }, [props.dt])
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }, [setValue])
    const onInput = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const newTs = valueToTs(r.current?.value, props.dt)
        if (newTs == props.dt || !newTs) {
            return
        }
        if (newTs < 0) {
            return
        }
        props.change(newTs)
    }, [props.change])
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const newTs = valueToTs(e.target.value, props.dt)
        if (newTs == props.dt) {
            return
        }
        setValue(e.target.value)
        props.change(newTs)
    }
    return (
        <input ref={r} type='date'
            value={value}
            onInput={onInput}
            onChange={onChange}
            onBlur={onBlur}
            disabled={props.disable}
        />
    )
}

function valueToDate(val: string | undefined, baseDt?: number): Date | undefined {
    if (!val) {
        return undefined
    }
    const dt = new Date(val)
    if (isNaN(dt as any)) {
        return undefined
    }
    if (!baseDt) {
        return dt
    }
    const cur = dtDate(baseDt)
    cur.setFullYear(dt.getFullYear())
    cur.setMonth(dt.getMonth())
    cur.setDate(dt.getDate())
    return cur
}

function valueToTs(val: string | undefined, baseDt?: number): number | undefined {
    const dt = valueToDate(val, baseDt)
    if (!dt) {
        return undefined
    }
    return dtToUnix(dt)
}


type PropsTime = {
    dt?: number
    change: (dt?: number) => void
    disable: boolean
}

export const InputTime: React.FC<PropsTime> = (props) => {
    const [value, setValue] = useState(props.dt ? dtToTimeString(props.dt) : '')
    useEffect(() => {
        setValue(props.dt ? dtToTimeString(props.dt) : '')
    }, [props.dt])
    const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        const dt = calcChangeTime(props.dt, v)
        if (dt) {
            props.change(dt)
        }
        setValue(v)
    }
    return (
        <input type='time' value={value} disabled={props.disable} onChange={changeTime}/>
    )
}

function calcChangeTime(dtm: number | undefined, time: string): number | undefined {
    const dt = dtm ? dtDate(dtm) : new Date()
    if (time === '') {
        return undefined
    }
    const [h, m] = strToHoursAndMinutes(time)
    dt.setHours(h)
    dt.setMinutes(m)
    return dtToUnix(dt, true)

}